/* 
<link rel="stylesheet" href="https://unpkg.com/bootstrap@5.3.2/dist/css/bootstrap.min.css" />
<link rel="stylesheet" href="https://unpkg.com/bs-brain@2.0.3/components/abouts/about-1/assets/css/about-1.css" /> */

.container h2{
    /* font-style: italic; */
    color: #4D150F;
}
.containerp::first-line{
    text-align: center;
}
.profile{
    border-radius: 30%;
}
/* .mainDiv{
    background-color: #E6E6E6;
} */
.history{
    color: #4D150F;
}
.footer{
    /* border: 1px solid red; */
    /* background-image: url("assests/RK.png"); */
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    gap: 20px;
    background-color: #5b4543;
    color: white;
    
}
.footer>div{
    /* border: 1px solid; */
    width: 30%;
    height: 100%;
}
.logo{
    
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    height: 70%;
    width: 100%;
}
.logo img{
    /* border: 1px solid red; */
    width: 250px;
    height: 130px;
}
.text{
    /* border: 1px solid red; */
    height: 30%;
}
.text h5{
    text-align: center;
}
.text p{
    text-align: center;
}
.empty{
    height: 60%;
    width: 100%; 
}
.socialIconsocialIcon{
    height: 20%; 
}
.socialIcon p{
    text-align: center; 
}
.icon{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
   /* width: 70%; */
   height: 20%;
   gap: 30px;
}
.footer_icon{
    font-size: 1.4rem;
    color: rgb(8, 8, 8);
  }
  .font_icon{
    font-size: 2.4rem;
  }
  .header{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    width: 100%;
    height: 200px;
  }
  .header img{
   width: 250px;
   height: 130px;
  }
  .titlebreif{
    /* border: 1px solid red; */
    margin-top: 60px;
  }

  /* media queries */

  @media screen and (min-width: 520px)and (max-width: 800px) {
    .footer{
        height: 400px;
        width: 100%;
        display: block;
        gap: 20px;
        background-color: #5b4543;
        color: white;  
        padding-bottom: 50px;   
    }

    .footer>div{
        /* border: 1px solid; */
        width: 100%;
        height: 30%;
    }
    .logo{
        
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid red; */
        height: 70%;
        width: 100%;
    }
    .logo img{
        /* border: 1px solid red; */
        width: 250px;
        height: 130px;
    }
    .text{
        margin-top: 50px;
        /* border: 1px solid red; */
        height: 30%;
    }
    .text h5{
        text-align: center;
    }
    .text p{
        text-align: center;
    }
    .empty{
        height: 60%;
        width: 100%; 
    }
    .socialDiv{
     margin-top: 30px;
    }
    .socialIconsocialIcon{
        height: 20%; 
    }
    .socialIcon p{
        text-align: center; 
    }
    .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid red; */
       /* width: 70%; */
       height: 20%;
       gap: 30px;
    }
    .footer_icon{
        font-size: 1.4rem;
        color: rgb(8, 8, 8);
      }
      .font_icon{
        font-size: 2.4rem;
      }
  }
  @media screen and (min-width: 801px)and (max-width: 977px) {
    .footer{
        height: 400px;
        width: 100%;
        display: block;
        gap: 20px;
        background-color: #5b4543;
        color: white;     
    }

    .footer>div{
        /* border: 1px solid; */
        width: 100%;
        height: 30%;
    }
    .logo{
        
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid red; */
        height: 70%;
        width: 100%;
    }
    .logo img{
        /* border: 1px solid red; */
        width: 250px;
        height: 130px;
    }
    .text{
        margin-top: 50px;
        /* border: 1px solid red; */
        height: 30%;
    }
    .text h5{
        text-align: center;
    }
    .text p{
        text-align: center;
    }
    .empty{
        height: 60%;
        width: 100%; 
    }
    .socialIconsocialIcon{
        height: 20%; 
    }
    .socialIcon p{
        text-align: center; 
    }
    .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid red; */
       /* width: 70%; */
       height: 20%;
       gap: 30px;
    }
    .footer_icon{
        font-size: 1.4rem;
        color: rgb(8, 8, 8);
      }
      .font_icon{
        font-size: 2.4rem;
      }
  
  }

  @media screen and (min-width: 292px)and (max-width: 519px)  {

    .footer{
        height: 400px;
        width: 100%;
        display: block;
        gap: 20px;
        background-color: #5b4543;
        color: white;     
    }

    .footer>div{
        /* border: 1px solid rgb(0, 2, 128); */
        width: 100%;
        height: 30%;
    }
    .logo{
        
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid red; */
        height: 70%;
        width: 100%;
    }
    .logo img{
        /* border: 1px solid red; */
        width: 250px;
        height: 120px;
    }
    .text{
        margin-top: 50px;
        /* border: 1px solid red; */
        height: 30%;
    }
    .text h5{
        text-align: center;
    }
    .text p{
        text-align: center;
    }
    .empty{
        height: 60%;
        width: 100%; 
    }
    .socialIconsocialIcon{
        height: 20%; 
    }
    .socialIcon p{
        text-align: center; 
    }
    .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid red; */
       /* width: 70%; */
       height: 20%;
       gap: 30px;
    }
    .footer_icon{
        font-size: 1.4rem;
        color: rgb(8, 8, 8);
      }
      .font_icon{
        font-size: 2.4rem;
      }
  }


  @media screen and (max-width: 293px) {

    .footer{
        height: 400px;
        width: 100%;
        display: block;
        gap: 20px;
        background-color: #5b4543;
        color: white;  
        padding-bottom: 50px; 
        padding-top:-50px;
    }

    .footer>div{
        /* border: 1px solid; */
        width: 100%;
        height: 30%;
    }
    .logo{
        
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid red; */
        height: 70%;
        width: 100%;
    }
    .logo img{
        /* border: 1px solid red; */
        width: 220px;
        height: 110px;
    }
    .text{
        margin-top: 50px;
        /* border: 1px solid red; */
        height: 30%;
    }
    .text h5{
        text-align: center;
    }
    .text p{
        text-align: center;
    }
    .empty{
        height: 60%;
        width: 100%; 
    }
    .socialDiv{
        margin-top: 30px;
    }
    .socialIconsocialIcon{
        height: 20%; 
    }
    .socialIcon p{
        text-align: center; 
    }
    .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid red; */
       /* width: 70%; */
       height: 20%;
       gap: 30px;
    }
    .footer_icon{
        font-size: 1.4rem;
        color: rgb(8, 8, 8);
      }
      .font_icon{
        font-size: 2.4rem;
      }
  }