.register_main{
    width: 100%;
    height: 100vh;
    position: relative;
}
.registerbtn{
    background-color:#1f76a3;
    color: white;
    transition: 0.5s ease-in-out;
}
.registerbtn:hover{
    background-color:#1f76a3;
    color: white; 
    box-shadow: rgba(0, 0, 0, 0.417) 3px 5px 10px;
}
.spin{
width: 20px;
height: 20px;
animation: circle 2s linear infinite;
}
@keyframes circle{
    100%{
       rotate: 360deg;
    }
}