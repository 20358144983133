.audience_main{
    width: 100%;
    height: 100vh;
    background-color: white;
}
body{
height: 100vh;
/* background-color: #def4f5; */
}
.cardDetail{
    width: 90%;
    margin: auto;
}
@media screen and (max-width: 520px) {
.left_serviceimg{
    height: 200px;
}
.scrollbarRight{
 visibility: hidden;
}    
.scrollbar{
    visibility: hidden;
   } 
  }