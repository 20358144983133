.about_box p{
    text-align: justify;
    text-justify: inter-word;
  }
  .team_btn{
    border: none;
    background-color:  #1b7678;
    color: white;
    border-radius: 10px;
    padding: 5px 10px;
  }