.contact_form{
    display: flex;
    flex-direction: column;

}
.contact_form input{
    margin-bottom: 1rem;
    border-radius: 10px;
    padding: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    outline: none;
}
.contact_form textarea{
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    outline: none;
}
.contact_form button{
    margin: auto;
    width: 30%;
    border-radius: 10px;
    padding: 10px;
    border: none;
    /* background-color: rgb(255, 255, 255); */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 1rem;
}
.contact_img {
   margin-bottom: 1rem;
}
.contact_img img{
width: 100%;
height: 80%;
}

@media screen and (max-width: 520px) {
    .contact_img img{
        width: 100%;
        height: 100%;
        }
  
        .contact_form button{

            width: 60%;
           
        }
   }


   .error_pop{
    color: red;
   }
   .error_succes{
    color: green;
   }