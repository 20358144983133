
.nav_main{
  
    width: 100%;
    position: relative;

  
}
.navMain{
    width: 100%;
}

.nav_main p{
    text-decoration: none;
    cursor: pointer;
    padding-top: 1rem;
    font-weight: 600;
font-size: 1rem;
}
.navright{
    width: 40%;
    border: 2px solid;
    display: flex;
    justify-content: space-around;
 height: 20px;
 align-items: center;
}

a{
    color: rgb(199, 77, 77);
    text-decoration: none;

} 
a:hover{
    color: rgb(224, 13, 13);
    text-decoration: none;  
} 


.nav_logo{
    width: 9%;
    height: 54px;
    position: absolute;
    top: 0;
    left: 0;
z-index: 1;
}

@media screen and (max-width: 520px) {
    .nav_logo{
        width: 17%;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
    
    }
  }
  

  /*  */
  
