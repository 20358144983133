.coustm_box{
    /* background-color: #E6E6E6; */
   min-width:120px;
    height: 100px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    transition: 0.5s ease-in-out;
    padding: 7px;
   box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

h2{
    color: #4D150F;
}

.imageheight{
  width: 100%;
  height: 100%;
}
.scrollimage{
  width: 100%;
  height: 100%;
}

.coustm_box:hover{
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.coustmer_main{
    /* border: 1px solid red; */
    width: 100%;
    overflow: hidden;
    padding: 50px ;
  
   
}
.coust_row{
    margin: auto;
    display: flex;
    justify-content: space-around;
    gap: 10px;
}


@media screen and (max-width: 540px) {
    .coustm_box{
        width: 150px;
    height: 150px;
    }
    .coust_row{
        margin: auto;
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }
    .image-container {
        display: flex;
        justify-content: space-between;
        width: calc(140px * 30);
        animation: move 35s linear infinite;
      }
      @keyframes move {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-140px * 30));
        }
      }
    
      .scrollimage_f{
        margin-top: 10px;
        width: 40%;
        height: 80%;
      }
      .founder_span{
        font-size: 0.5rem;
      }
  }



  @media screen and (min-width: 541px) {
    .image-container {
        display: flex;
        justify-content: space-between;
        width: calc(200px * 20);
        animation: move 35s linear infinite;
      }
      
      @keyframes move {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-140px * 20));
        }
      }
   
      .scrollimage_f{
        margin-top: 10px;
        width: 40%;
        height: 80%;
      }
      .founder_span{
        font-size: 0.5rem;
      }
      
  }
