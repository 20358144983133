
.coustm_box{
    width: 130px;
    height: 130px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    transition: 0.5s ease-in-out;
    padding: 10px;
   color: black;
   box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.imageheight{
  width: 100%;
  height: 40px;
}
.scrollimage{
  width: 100%;
  height: 100%;
}

.coustm_box:hover{
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.coustmer_main{
    width: 100%;
    overflow: hidden;
    padding: 50px ;
   
}
.coust_row{
    margin: auto;
    display: flex;
    justify-content: space-around;
    gap: 20px;
}


@media screen and (max-width: 540px) {
    .coustm_box{
        width: 150px;
    height: 150px;
    }
    .coust_row{
        margin: auto;
        display: flex;
        justify-content: space-between;
        gap: 0px;
    }
    .image-container {
        display: flex;
        justify-content: space-between;
        width: calc(100px * 25);
        animation: move 35s linear infinite;
      }
      @keyframes move {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-90px * 20));
        }
      }
    
      .scrollimage_f{
        margin-top: 10px;
        width: 40%;
        height: 80%;
      }
      .founder_span{
        font-size: 0.5rem;
      }
  }



  @media screen and (min-width: 541px) {
    .image-container {
        display: flex;
        justify-content: space-between;
        width: calc(200px * 10);
        animation: move 35s linear infinite;
      }
      
      @keyframes move {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-90px * 10));
        }
      }
   
      .scrollimage_f{
        margin-top: 10px;
        width: 40%;
        height: 80%;
      }
      .founder_span{
        font-size: 0.5rem;
      }
      
  }

  /* ........ */
  