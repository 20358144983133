*{
    padding: 0;
    margin: 0;
}
::-webkit-scrollbar{
    width: 10px;
 

}
::-webkit-scrollbar-thumb{
border-radius: 15px;
   background-color: rgb(85, 90, 87);
}
.tanunPage{

    background-image: url('../../../public/assests/TANUNBanner.png');
    width: 100%;
    max-height: 100%;
    overflow: hidden;
        min-height: 100vh;
        position: relative;
    color: #202121;
  
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;

}
.tanunPages{
  
    overflow: hidden;
    height: 100vh;
    font-size: 1.4rem;
}
.bannerIMG{
    height: 28vh;
}
.bannerIMG img{
    width: 100%;
    height: 100%;
}
.tanunLogo{
    height: 13vh;
}
.tanunLogo img{
    width: 100%;
    height: 100%;
}
.tanunPages>p{
    text-wrap: wrap;
}
.Tanunheading{
    font-size: 2.6rem;
    color: rgb(46, 211, 46);
}
.tanunPara{
    font-size: 1.3rem;
}


.tanunPhead {
font-size: 2.3rem;
}
.intro_p{
    font-size: 1.5rem;
}

.lasthead{
    font-size: 2rem;
}
@media screen and (min-width: 521px)and (max-width: 939px) {
    .tanunPages{
  
        height: 100%;
        font-size: 1.2rem;
    }
    .bannerIMG{
        height: 25vh;
    }
    .intro_p{
        font-size: 1.3rem;
    }
    .Tanunheading{
        font-size: 2.4rem;
      
    }
    .tanunPara{
        font-size: 1.2rem;
    }
    .tanunPhead {
        font-size: 2rem;
        }
        .lasthead{
            font-size: 1.9rem;
        }
        .tanunLogo{
            height: 5vh;
        }
  }

  @media screen and (max-width: 520px) {
    .tanunPages{
       
        height: 100%;
        font-size: 1.1rem;
    }
    .bannerIMG{
        position: absolute;
        bottom: 0;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        height: 18vh;
    }
    .intro_p{
        font-size: 0.8rem;
    }
    .Tanunheading{
        font-size: 1.5rem;
      
    }
    .tanunLogo{
        height: 7vh;
    }
    .tanunPara{
        font-size: 0.8rem;
    }
    .tanunPhead {
        font-size: 1.5rem;
        }
        .lasthead{
            font-size: 1.5rem;
        }
        .tanunPage{
         
            background-position: 70%;
            background-size:cover;
            background-repeat: no-repeat;
        
        }
      
  }